import * as dayjs from 'dayjs';
import dayjs__default from 'dayjs';
import { SearchComponentType, SearchOperationType, SearchFilterType, CUSTOM_ICONS } from '@zeiss/ng-vis-common/types';

/**
 * Cast any object into the provided type.
 * @param {any} value an object without a specific type
 * @returns {T} the input value as the given type
 */
function assertAs(value) {
  return value;
}

/*
 * This function is obsolete if lib is ES2019 as this is available natively there with Array.flatMap();
 */
function flatten(arr, result = []) {
  for (let i = 0, length = arr.length; i < length; i++) {
    const value = arr[i];
    if (Array.isArray(value)) {
      flatten(value, result);
    } else {
      result.push(value);
    }
  }
  return result;
}

/**
 * Sorts an array by the given key(s) starting with the max value.
 *
 * max is depending on number and/or alphabetical
 * @param collection the array which should be sorted
 * @param keys keys in the order of sorting
 * @returns sorted array
 */
const maxBy = (collection, ...keys) => {
  const select = (a, b) => {
    let aa = a;
    let bb = b;
    for (const key of keys) {
      aa = aa[key];
      bb = bb[key];
    }
    return aa >= bb ? a : b;
  };
  return collection.reduce(select, {});
};

/**
 * Sorts an array by the given key(s) starting with the min value.
 *
 * min is depending on number and/or alphabetical
 * @param collection the array which should be sorted
 * @param keys keys in the order of sorting
 * @returns sorted array
 */
const minBy = (collection, ...keys) => {
  const select = (a, b) => {
    let aa = a;
    let bb = b;
    for (const key of keys) {
      aa = aa[key];
      bb = bb[key];
    }
    return aa <= bb ? a : b;
  };
  return collection.reduce(select, {});
};

/**
 * ### Description
 * Removes the given keys from the given object and returns the manipulated object.
 * ### Usage
 * ```
 * obj = omit(obj, ["key1", "key2", ...]);
 * ```
 */
function omit(object, ...keys) {
  const entries = Object.entries(object).filter(([k]) => !keys.includes(k));
  const obj = fromEntries(entries);
  return obj;
}
/**
 * Need to implement the Object.fromEntries manually. This function is a native function in ES2019 and also in a new Typescript-Version.
 * In future we may evaluate (after a typescript update) if we can replace this function with the native one.
 */
function fromEntries(entries) {
  const obj = entries.reduce((acc, [key, value]) => ({
    ...acc,
    [key]: value
  }), {});
  return obj;
}

/**
 * ### Description
 * Removes all keys except the given ones from the given object and returns the manipulated object.
 * ### Usage
 * ```
 * obj = pick(obj, ["key1", "key2", ...]);
 * ```
 */
function pick(object, keys) {
  return keys.reduce((obj, key) => {
    if (object && object.hasOwnProperty(key)) {
      obj[key] = object[key];
    }
    return obj;
  }, {});
}

/**
 * ### Description
 * Removes the given items from the array and returns a new array.
 * ### Usage
 * ```
 * array = pull(array, [item1, item2, ...]);
 * ```
 */
function pull(arr, ...removeList) {
  var removeSet = new Set(removeList);
  return arr.filter(function (el) {
    return !removeSet.has(el);
  });
}

/**
 * ### Description
 * Sorts an array by property name.
 *
 * _Manipulates the array._
 * ### Usage
 * ```
 * array.concat().sort(sortBy("property"));
 * ```
 */
const sortByPropName = (key, options = new SortOptions()) => {
  return (a, b) => {
    if (!b[key]) {
      return options.takeNullishValuesFirst ? 1 : -1;
    } else if (!a[key]) {
      return options.takeNullishValuesFirst ? -1 : 1;
    }
    if (dayjs.isDayjs(a[key]) || dayjs.isDayjs(b[key])) {
      return a[key].valueOf() < b[key].valueOf() ? 1 : a[key].valueOf() > b[key].valueOf() ? -1 : 0;
    }
    if (typeof a[key] === 'string' || typeof b[key] === 'string') {
      const c = a[key].toLowerCase();
      const d = b[key].toLowerCase();
      return c > d ? 1 : c < d ? -1 : 0;
    }
    return a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0;
  };
};
class SortOptions {
  constructor() {
    this.takeNullishValuesFirst = false;
  }
}

/**
 * ### Description
 * Sorts an array by property.
 *
 * _Manipulates the array._
 * ### Usage
 * ```
 * array.concat().sort(sortBy((element) => (element.property)));
 * ```
 */
const sortBy = getProperty => (objectA, objectB) => {
  const a = getProperty(objectA);
  const b = getProperty(objectB);
  if (!b) {
    return -1;
  } else if (!a) {
    return 1;
  }
  if (dayjs.isDayjs(a) || dayjs.isDayjs(b)) {
    return a.valueOf() < b.valueOf() ? 1 : a.valueOf() > b.valueOf() ? -1 : 0;
  }
  return a > b ? 1 : a < b ? -1 : 0;
};

/**
 * Filters an array by a unique given key/value
 * @param arr
 * @param predicate the key/value
 * @returns filtered array
 */
const uniqBy = (arr, predicate) => {
  const cb = typeof predicate === 'function' ? predicate : o => o[predicate];
  return [...arr.reduce((map, item) => {
    const key = item === null || item === undefined ? item : cb(item);
    map.has(key) || map.set(key, item);
    return map;
  }, new Map()).values()];
};

/**
 * ### Description
 * Parses names and returns initials and cleaned name parts.
 *
 * _Also supports emailaddresses._
 *
 * ### Examples
 * ```
 * parseName('John Doe')
 * // returns
 * {
 *   initialsFirstCharacter: 'J',
 *   initialsLastCharacter: 'D',
 *   initials: 'JD',
 *   nameParts: ['John', 'Doe']
 * }
 *
 * parseName('John')
 * // returns
 * {
 *   initialsFirstCharacter: 'J',
 *   initialsLastCharacter: 'O',
 *   initials: 'JO',
 *   nameParts: ['John']
 * }
 *
 * parseName('john.doe@example.com')
 * // returns
 * {
 *   initialsFirstCharacter: 'J',
 *   initialsLastCharacter: 'D',
 *   initials: 'JD',
 *   nameParts: ['john', 'doe']
 * }
 *
 * parseName('Manfred', 'von hinterm Berg')
 * // returns
 * {
 *   initialsFirstCharacter: 'M',
 *   initialsLastCharacter: 'B',
 *   initials: 'MB',
 *   nameParts: ['Manfred', 'von', 'hinterm', 'Berg']
 * }
 * ```
 */
function parseName(...names) {
  let nameParts = [];
  names.forEach(dirtyName => {
    const name = (dirtyName ?? '').trim();
    if (name.length < 1) {
      return;
    }
    const parts = name.split(' ').filter(x => x.length > 0);
    // if part is an email address, only take the part before the '@' sign
    for (let i = 0; i < parts.length; i++) {
      parts[i] = parts[i].split('@')[0];
      const temp = parts[i].replace('.', ' ').replace(',', ' ').split(' ');
      nameParts = nameParts.concat(temp);
    }
  });
  const initialsFirstCharacter = (nameParts.length > 0 ? nameParts[0][0] ?? '' : '').toUpperCase();
  const initialsLastCharacter = (nameParts.length > 1 ? nameParts[nameParts.length - 1][0] ?? '' : nameParts.length > 0 ? nameParts[0].length > 1 ? nameParts[0][1] : '' : '').toUpperCase();
  return {
    initialsFirstCharacter,
    initialsLastCharacter,
    initials: initialsFirstCharacter + initialsLastCharacter,
    names: nameParts
  };
}
var Enum;
(function (Enum) {
  function keys(obj) {
    const _keys = Object.keys(obj);
    let _allEnumPropsCount = 0;
    for (const key in obj) {
      if (typeof obj[key] === 'string') {
        _allEnumPropsCount++;
      }
    }
    // obj is a string enum
    if (_allEnumPropsCount === _keys.length) {
      return _keys;
    }
    // obj is a numeric enum
    const _keysFiltered = _keys.splice(0, _keys.length / 2);
    return _keysFiltered.map(_key => +_key);
  }
  Enum.keys = keys;
  function values(obj) {
    const _values = Object.values(obj);
    let _allEnumPropsCount = 0;
    for (const key in obj) {
      if (typeof obj[key] === 'string') {
        _allEnumPropsCount++;
      }
    }
    // obj is a string enum
    if (_allEnumPropsCount === _values.length) {
      return _values;
    }
    // obj is a numeric enum
    return _values.splice(0, _values.length / 2);
  }
  Enum.values = values;
})(Enum || (Enum = {}));
function queryParamsToFilters(params, searchConfig) {
  const filters = [];
  Object.keys(params).forEach(key => {
    const currentConfig = searchConfig.find(config => config.name === key);
    const current = currentConfig ? JSON.parse(JSON.stringify(currentConfig ?? {})) : undefined;
    // Border case for date ranges, split values for example fromToEntryDate={dateFrom}-{dateTo}
    if (current?.componentType === SearchComponentType.DATE_RANGE && params[key]?.length > 0) {
      const values = params[key].split(',');
      current.filterPair?.forEach(el => {
        el.value = el.operation === SearchOperationType.GREATER ? values[0] : values[1];
      });
      filters.push(current);
    } else if (current?.filter) {
      switch (current.filter.type) {
        case SearchFilterType.String:
          current.filter.value = params[key]?.length > 0 ? params[key] : '';
          break;
        case SearchFilterType.List:
          current.filter.value = params[key]?.length > 0 ? params[key].split(',') : [];
          break;
        case SearchFilterType.Date:
          current.filter.value = params[key]?.length > 0 ? dayjs__default(params[key]) : undefined;
          break;
      }
      filters.push(current);
    }
  });
  return filters;
}
function filtersToQueryParams(filters, searchConfig, currentParams) {
  const params = {
    ...(currentParams || {})
  };
  if (filters?.length === 0) {
    Object.keys(params).forEach(key => {
      if (key !== 'env' && key !== 'tab') params[key] = null;
    });
    return params;
  }
  searchConfig.forEach(config => {
    const filterConfig = filters.find(el => el.name === config.name);
    const filter = filterConfig?.filter;
    // Border case for date ranges
    if (config?.componentType === SearchComponentType.DATE_RANGE && filterConfig?.filterPair?.length && filterConfig?.filterPair?.some(el => el.value)) {
      const from = filterConfig.filterPair.find(el => el.operation === SearchOperationType.GREATER)?.value;
      const to = filterConfig.filterPair.find(el => el.operation === SearchOperationType.LESS)?.value;
      params[config.name] = `${from ?? ''}${to ? `,${to}` : ''}`;
    } else if (filter?.value) {
      if (filter.type === SearchFilterType.String && filter.value.length > 0) {
        params[config.name] = filter.value;
      } else if (filter.type === SearchFilterType.List && filter.value.length > 0) {
        const val = Array.isArray(filter.value) ? filter.value.join(',') : filter.value;
        params[config.name] = val.length > 0 ? val : null;
      } else if (filter.type === SearchFilterType.Date) {
        params[config.name] = dayjs__default(filter.value).toISOString();
      } else {
        params[config.name] = null;
      }
    } else {
      params[config.name] = null;
    }
  });
  return params;
}
function handleFilterCases(filters) {
  filters.forEach(config => {
    const filtersKey = config.key;
    const filtersType = config.type;
    // Remove last comma and split string in to array
    if ((filtersKey === 'globalOrderId' || filtersKey === 'orderId' || filtersKey === 'rxPassId') && (filtersType === 'string' || filtersType === 'string[]')) {
      if (config.value.slice(-1) === ',') {
        config.value = config.value.slice(0, -1);
        config.value = config.value.toString().split(',');
      }
    }
    if (filtersKey === 'globalOrderId' || filtersKey === 'orderId' || filtersKey === 'rxPassId') {
      let value = config.value;
      if (typeof value === 'string') {
        value = value.split(',');
      }
      if (Array.isArray(value)) {
        config.operation = SearchOperationType.CONTAINED_IN;
        config.type = SearchFilterType.List;
      } else {
        config.operation = SearchOperationType.CONTAINS;
        config.type = SearchFilterType.String;
      }
      config.value = value;
    }
    if (Array.isArray(config.value) && config.value.length === 1) {
      config.value = config.value[0];
      config.operation = SearchOperationType.CONTAINS;
      config.type = SearchFilterType.String;
    }
  });
  return filters;
}

/**
 * Use to get the correct custom icon names from `CUSTOM_ICONS`.
 *
 * @example
 * ```html
 * <vis-icon [name]="customIcon('custom-dimensions-weight-warning')" mode="ng-svg" />
 * ```
 */
function customIcon(iconName) {
  return iconName;
}
function registerCustomIcons(matIconRegistry, domSanitizer) {
  for (const _key in CUSTOM_ICONS) {
    const key = _key;
    matIconRegistry.addSvgIcon(key, domSanitizer.bypassSecurityTrustResourceUrl(CUSTOM_ICONS[key]));
  }
}
function fixZuiSearchbarsWithFilter() {
  Array.from(document.getElementsByTagName('zui-searchbar-input')).map(searchbarElement => {
    const inputWrapper = searchbarElement.shadowRoot?.querySelector('.input-wrapper');
    if (inputWrapper) inputWrapper.style.display = 'flex';
  });
}
function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Uses Math Round, but it muliply the value before Round
 * and divides after Round with divider
 * @param multiplicator
 * @param divider
 */
function round(value, multiplicator = 1, divider = 100) {
  return Math.round(value * multiplicator) / divider;
}

/**
 * Generated bundle index. Do not edit.
 */

export { Enum, SortOptions, assertAs, capitalizeFirstLetter, customIcon, filtersToQueryParams, fixZuiSearchbarsWithFilter, flatten, handleFilterCases, maxBy, minBy, omit, parseName, pick, pull, queryParamsToFilters, registerCustomIcons, round, sortBy, sortByPropName, uniqBy };
