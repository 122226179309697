import { InjectionToken } from '@angular/core';
const AUTH_GUARD = new InjectionToken('AUTH_GUARD');
const AUTH_SERVICE = new InjectionToken('AUTH_SERVICE');
const RULE_PROTECT_SERVICE = new InjectionToken('RULE_PROTECT_SERVICE');
const ROUTER_SERVICE = new InjectionToken('ROUTER_SERVICE');

/**
 * Generated bundle index. Do not edit.
 */

export { AUTH_GUARD, AUTH_SERVICE, ROUTER_SERVICE, RULE_PROTECT_SERVICE };
