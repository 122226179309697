import { nothing as i } from "../lit-html.js";
import { AsyncDirective as t } from "../async-directive.js";
import { directive as s } from "../directive.js";
/**
 * @license
 * Copyright 2020 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const e = () => new o();
class o {}
const h = new WeakMap(),
  n = s(class extends t {
    render(t) {
      return i;
    }
    update(t, [s]) {
      var e;
      const o = s !== this.G;
      return o && void 0 !== this.G && this.ot(void 0), (o || this.rt !== this.lt) && (this.G = s, this.dt = null === (e = t.options) || void 0 === e ? void 0 : e.host, this.ot(this.lt = t.element)), i;
    }
    ot(i) {
      var t;
      if ("function" == typeof this.G) {
        const s = null !== (t = this.dt) && void 0 !== t ? t : globalThis;
        let e = h.get(s);
        void 0 === e && (e = new WeakMap(), h.set(s, e)), void 0 !== e.get(this.G) && this.G.call(this.dt, void 0), e.set(this.G, i), void 0 !== i && this.G.call(this.dt, i);
      } else this.G.value = i;
    }
    get rt() {
      var i, t, s;
      return "function" == typeof this.G ? null === (t = h.get(null !== (i = this.dt) && void 0 !== i ? i : globalThis)) || void 0 === t ? void 0 : t.get(this.G) : null === (s = this.G) || void 0 === s ? void 0 : s.value;
    }
    disconnected() {
      this.rt === this.lt && this.ot(void 0);
    }
    reconnected() {
      this.ot(this.lt);
    }
  });
export { e as createRef, n as ref };
