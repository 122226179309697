/**
 * @version 4.2.0
 * @generated Tue, 06 Aug 2024 08:45:57 GMT
 * @description These rules are finally grouped into roles, which can be assigned to Vision Portal accounts.
 */
const RULE = {
  /**
   * @description Access to the Authorization module
   * @package		`1.7.3`
   */
  access_auth: {
    id: 'access_auth',
    allowed: []
  },
  /**
   * @description Access to the Central Inventory Management (CIM) module
   * @package		`3.0.4`
   */
  access_cim: {
    id: 'access_cim',
    allowed: []
  },
  /**
   * @description Access to the DDP module
   * @package		`2.33.2`
   */
  access_ddp: {
    id: 'access_ddp',
    allowed: []
  },
  /**
   * @description Access to the DDP module
   * @package		`4.1.13`
   */
  access_ddp_v1: {
    id: 'access_ddp_v1',
    allowed: []
  },
  /**
   * @description Access to the Developer module
   * @package		`2.28.15`
   */
  access_dev: {
    id: 'access_dev',
    allowed: []
  },
  /**
   * @description Access to the Documentation module
   * @package		`1.6.0`
   */
  access_documentation: {
    id: 'access_documentation',
    allowed: []
  },
  /**
   * @description Permissions to acccess the ESB-UI
   * @package		`4.1.17`
   */
  access_esb: {
    id: 'access_esb',
    allowed: []
  },
  /**
   * @description Access to the GBP module
   * @package		`2.20.5`
   */
  access_gbp: {
    id: 'access_gbp',
    allowed: []
  },
  /**
   * @description General rule for accessing the gdpl library from VP
   * @package		`3.11.9`
   */
  access_gdpl: {
    id: 'access_gdpl',
    allowed: []
  },
  /**
   * @description Access to the global GSS module
   * @package		`1.19.2`
   */
  access_gss: {
    id: 'access_gss',
    allowed: []
  },
  /**
   * @description Access to the Lenspass Portal
   * @package		`2.1.3`
   */
  access_lpp: {
    id: 'access_lpp',
    allowed: []
  },
  /**
   * @description Access to the Material module
   * @package		`2.27.4`
   */
  access_material: {
    id: 'access_material',
    allowed: []
  },
  /**
   * @description Access to the MDDB module
   * @package		`1.19.3`
   */
  access_mddb: {
    id: 'access_mddb',
    allowed: []
  },
  /**
   * @description Access to the OMR module
   * @package		`2.27.2`
   */
  access_omr: {
    id: 'access_omr',
    allowed: []
  },
  /**
   * @description Access to the OTD module
   * @package		`1.19.6`
   */
  access_otd: {
    id: 'access_otd',
    allowed: []
  },
  /**
   * @description Access to specific PRs
   * @package		`4.1.12`
   */
  access_prs: {
    id: 'access_prs',
    allowed: []
  },
  /**
   * @description Users with this rule allowed to manage repositories for the PR overview
   * @package		`4.1.14`
   */
  access_repo_management: {
    id: 'access_repo_management',
    allowed: []
  },
  /**
   * @description Access to the skillmatrix module
   * @package		`4.1.9`
   */
  access_skillmatrix: {
    id: 'access_skillmatrix',
    allowed: []
  },
  /**
   * @description Access to the Status Management module
   * @package		`2.1.3`
   */
  access_status: {
    id: 'access_status',
    allowed: []
  },
  /**
   * @description Access to the TST module
   * @package		`3.10.5`
   */
  access_tst: {
    id: 'access_tst',
    allowed: []
  },
  /**
   * @description Access to the Utilities module
   * @package		`4.2.0`
   */
  access_utilities: {
    id: 'access_utilities',
    allowed: []
  },
  /**
   * @description Access to Wiki Features
   * @package		`2.27.4`
   */
  access_wiki: {
    id: 'access_wiki',
    allowed: []
  },
  /**
   * @description Functionalities inside the Announcement Management UI.
   * @package		`2.20.21`
   */
  admin_announcements_functions: {
    id: 'admin_announcements_functions',
    allowed: []
  },
  /**
   * @description Functionalities inside authorization module, permissions area
   * @package		`2.28.16`
   */
  auth_permissions_functions: {
    id: 'auth_permissions_functions',
    allowed: []
  },
  /**
   * @description Blobstorage locations
   * @package		`2.20.21`
   */
  blobdownload_area: {
    id: 'blobdownload_area',
    allowed: []
  },
  /**
   * @description Activity type map
   * @package		`4.0.3`
   */
  ddp_activity_type_map: {
    id: 'ddp_activity_type_map',
    allowed: []
  },
  /**
   * @description Communication status
   * @package		`4.0.3`
   */
  ddp_communication_status: {
    id: 'ddp_communication_status',
    allowed: []
  },
  /**
   * @description DDP Cut Off
   * @package		`4.1.6`
   */
  ddp_cut_off: {
    id: 'ddp_cut_off',
    allowed: []
  },
  /**
   * @description DDP lane map
   * @package		`4.0.3`
   */
  ddp_lane_map: {
    id: 'ddp_lane_map',
    allowed: []
  },
  /**
   * @description Access rights to sales organisations within DDP features.
   * @package		`2.23.1`
   */
  ddp_sales_orgs: {
    id: 'ddp_sales_orgs',
    allowed: []
  },
  /**
   * @description DDP Shifts
   * @package		`4.0.2`
   */
  ddp_shifts: {
    id: 'ddp_shifts',
    allowed: []
  },
  /**
   * @description Functionalities inside the PR overview / management UI
   * @package		`1.19.5`
   */
  dev_pullrequest_functions: {
    id: 'dev_pullrequest_functions',
    allowed: []
  },
  /**
   * @description Documentation files: API
   * @package		`2.28.1`
   */
  doc_file_api: {
    id: 'doc_file_api',
    allowed: []
  },
  /**
   * @description Documentation files: DB
   * @package		`2.20.21`
   */
  doc_file_db: {
    id: 'doc_file_db',
    allowed: []
  },
  /**
   * @description Documentation files: Packages
   * @package		`2.28.24`
   */
  doc_file_package: {
    id: 'doc_file_package',
    allowed: []
  },
  /**
   * @description Rule which defines the operations which can be executed on an individual.
   * @package		`2.31.0`
   */
  gbp_individuals_function: {
    id: 'gbp_individuals_function',
    allowed: []
  },
  /**
   * @description Indicates to which system a user/system is allowed to manually broadcast data to.
   * @package		`3.4.0`
   */
  gbp_manual_broadcast_function: {
    id: 'gbp_manual_broadcast_function',
    allowed: []
  },
  /**
   * @description Indicates which operations a user/system can execute on metadata.
   * @package		`2.30.1`
   */
  gbp_metadata_function: {
    id: 'gbp_metadata_function',
    allowed: []
  },
  /**
   * @description Defines which operations a user is allowed to execute on organizations
   * @package		`2.31.1`
   */
  gbp_organization_function: {
    id: 'gbp_organization_function',
    allowed: []
  },
  /**
   * @description The parameter_types which users are allowed to see.
   * @package		`3.8.1`
   */
  gbp_parameter_types: {
    id: 'gbp_parameter_types',
    allowed: []
  },
  /**
   * @description Indicates which operations a user/system is allowed to execute on partners.
   * @package		`2.31.0`
   */
  gbp_partner_function: {
    id: 'gbp_partner_function',
    allowed: []
  },
  /**
   * @description SalesOrgs which can be assigned to GBP users.
   * @package		`3.10.4`
   */
  gbp_salesorgs: {
    id: 'gbp_salesorgs',
    allowed: []
  },
  /**
   * @description Permissions to the GSS AdminUI
   * @package		`2.20.21`
   */
  gss_adminui: {
    id: 'gss_adminui',
    allowed: []
  },
  /**
   * @description Required for specific template sales orgs which should be available for configuration.
   * @package		`3.4.2`
   */
  gss_configure_comui: {
    id: 'gss_configure_comui',
    allowed: []
  },
  /**
   * @description Permissions for the GSS Data Service
   * @package		`2.22.1`
   */
  gss_dataservice: {
    id: 'gss_dataservice',
    allowed: []
  },
  /**
   * @description Functionalities which are enabled for specific user
   * @package		`1.19.2`
   */
  gss_functions: {
    id: 'gss_functions',
    allowed: []
  },
  /**
   * @description All available sales orgs which can be assigned to a role to manage this sales org
   * @package		`3.4.2`
   */
  gss_sales_orgs: {
    id: 'gss_sales_orgs',
    allowed: []
  },
  /**
   * @description Which templates of specified sales orgs can be view in the ComUI
   * @package		`3.4.2`
   */
  gss_view_comui: {
    id: 'gss_view_comui',
    allowed: []
  },
  /**
   * @description Acces to the different mddb labs
   * @package		`3.1.0`
   */
  mddb_lab_access: {
    id: 'mddb_lab_access',
    allowed: []
  },
  /**
   * @description Functionalities inside MDDB, master
   * @package		`1.19.1`
   */
  mddb_master_functions: {
    id: 'mddb_master_functions',
    allowed: []
  },
  /**
   * @description Functionalities inside MDDB, meta
   * @package		`1.19.1`
   */
  mddb_meta_functions: {
    id: 'mddb_meta_functions',
    allowed: []
  },
  /**
   * @description Functionalities inside MDDB, object
   * @package		`1.19.1`
   */
  mddb_object_functions: {
    id: 'mddb_object_functions',
    allowed: []
  },
  /**
   * @description Functionalities inside MDDB, Object relationship
   * @package		`1.19.1`
   */
  mddb_objectrelationship_functions: {
    id: 'mddb_objectrelationship_functions',
    allowed: []
  },
  /**
   * @description Functionalities inside OMR module, error-handling
   * @package		`1.19.1`
   */
  omr_error_handling_functions: {
    id: 'omr_error_handling_functions',
    allowed: []
  },
  /**
   * @description Functionalities inside OMR, ESB Configuration
   * @package		`1.19.3`
   */
  omr_esb_configuration_functions: {
    id: 'omr_esb_configuration_functions',
    allowed: []
  },
  /**
   * @description Functionalities inside OMR module, OCT
   * @package		`3.11.7`
   */
  omr_oct_functions: {
    id: 'omr_oct_functions',
    allowed: []
  },
  /**
   * @description Sales organisations accessible to the user
   * @package		`4.1.11`
   */
  omr_oct_sales_organisation: {
    id: 'omr_oct_sales_organisation',
    allowed: []
  },
  /**
   * @description Functionalities inside OMR module, rxpass
   * @package		`3.5.1`
   */
  omr_rxpass_functions: {
    id: 'omr_rxpass_functions',
    allowed: []
  },
  /**
   * @description Functionalities inside OTD e2e report
   * @package		`2.27.0`
   */
  otd_e2e_functions: {
    id: 'otd_e2e_functions',
    allowed: []
  },
  /**
   * @description OTD E2E countries
   * @package		`2.20.22`
   */
  otd_manual_data_insert_report_hierarchy_2: {
    id: 'otd_manual_data_insert_report_hierarchy_2',
    allowed: []
  },
  /**
   * @description Functionalities inside OTD Rx report
   * @package		`2.27.1`
   */
  otd_rx_functions: {
    id: 'otd_rx_functions',
    allowed: []
  },
  /**
   * @description All Rx Labs
   * @package		`2.26.0`
   */
  otd_rx_lab: {
    id: 'otd_rx_lab',
    allowed: []
  },
  /**
   * @description Countries the user is allowed to review Rx passes for
   * @package		`2.26.2`
   */
  poh_rx_pass_countries: {
    id: 'poh_rx_pass_countries',
    allowed: []
  },
  /**
   * @description This rule is used to control languaged based reviews of RX Passes.
   * @package		`3.11.6`
   */
  poh_rxpass_languages: {
    id: 'poh_rxpass_languages',
    allowed: []
  },
  /**
   * @description Controls the backend to respond passes that are initially uploaded or reuploaded.
   * @package		`3.1.2`
   */
  poh_rxpass_request_types: {
    id: 'poh_rxpass_request_types',
    allowed: []
  },
  /**
   * @description Functions to maintain the settings in the settings tool (admin stuff)
   * @package		`3.10.2`
   */
  settingstool_admin_functions: {
    id: 'settingstool_admin_functions',
    allowed: []
  },
  /**
   * @description Access to the applications inside the settings tool
   * @package		`2.28.23`
   */
  settingstool_applications: {
    id: 'settingstool_applications',
    allowed: []
  },
  /**
   * @description Functions to maintain the configurations in the settingstool
   * @package		`4.1.8`
   */
  settingstool_base_functions: {
    id: 'settingstool_base_functions',
    allowed: []
  },
  /**
   * @description Global VP functions
   * @package		`2.28.9`
   */
  system_functions: {
    id: 'system_functions',
    allowed: []
  },
  /**
   * @description Responsibilities per task type
   * @package		`1.8.6`
   */
  task_responsibility: {
    id: 'task_responsibility',
    allowed: []
  },
  /**
   * @description The types of legacy tasks. ⚠️ Note that updating the rule values also require updating the LODB!
   * @package		`3.11.0`
   */
  task_responsibility_legacy: {
    id: 'task_responsibility_legacy',
    allowed: []
  },
  /**
   * @description testtesttest
   * @package		`3.6.0`
   */
  testtest: {
    id: 'testtest',
    allowed: []
  },
  /**
   * @description Access to the attached applications inside the translation tool
   * @package		`3.4.5`
   */
  translations_applications: {
    id: 'translations_applications',
    allowed: []
  },
  /**
   * @description Functionalities inside translation tool
   * @package		`2.28.10`
   */
  translations_functions: {
    id: 'translations_functions',
    allowed: []
  },
  /**
   * @description Available languages in the translation tool
   * @package		`2.28.10`
   */
  translations_languages: {
    id: 'translations_languages',
    allowed: []
  },
  /**
   * @description Detailed access to the flow screen
   * @package		`2.26.0`
   */
  tst_access_flow: {
    id: 'tst_access_flow',
    allowed: []
  },
  /**
   * @description Functionalities inside TST, config feature
   * @package		`1.19.4`
   */
  tst_config_functions: {
    id: 'tst_config_functions',
    allowed: []
  },
  /**
   * @description Network Sub Entities the user has access to.
   * @package		`3.10.3`
   */
  tst_network_sub_entities: {
    id: 'tst_network_sub_entities',
    allowed: []
  },
  /**
   * @description Shipment types the user has access to
   * @package		`3.10.6`
   */
  tst_shipment_types: {
    id: 'tst_shipment_types',
    allowed: []
  },
  /**
   * @description Functionalities inside utilities invite module
   * @package		`2.16.0`
   */
  utilities_invite_functions: {
    id: 'utilities_invite_functions',
    allowed: []
  },
  /**
   * @description Functionalities for the Wiki Feature
   * @package		`2.28.13`
   */
  wiki_functions: {
    id: 'wiki_functions',
    allowed: []
  }
};

/**
 * Generated bundle index. Do not edit.
 */

export { RULE };
