import { isSingleExpression as i } from "./directive-helpers.js";
import { Directive as t, PartType as e } from "./directive.js";
export { Directive, PartType, directive } from "./directive.js";
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const s = (i, t) => {
    var e, o;
    const r = i._$AN;
    if (void 0 === r) return !1;
    for (const i of r) null === (o = (e = i)._$AO) || void 0 === o || o.call(e, t, !1), s(i, t);
    return !0;
  },
  o = i => {
    let t, e;
    do {
      if (void 0 === (t = i._$AM)) break;
      e = t._$AN, e.delete(i), i = t;
    } while (0 === (null == e ? void 0 : e.size));
  },
  r = i => {
    for (let t; t = i._$AM; i = t) {
      let e = t._$AN;
      if (void 0 === e) t._$AN = e = new Set();else if (e.has(i)) break;
      e.add(i), l(t);
    }
  };
function n(i) {
  void 0 !== this._$AN ? (o(this), this._$AM = i, r(this)) : this._$AM = i;
}
function h(i, t = !1, e = 0) {
  const r = this._$AH,
    n = this._$AN;
  if (void 0 !== n && 0 !== n.size) if (t) {
    if (Array.isArray(r)) for (let i = e; i < r.length; i++) s(r[i], !1), o(r[i]);else null != r && (s(r, !1), o(r));
  } else s(this, i);
}
const l = i => {
  var t, s, o, r;
  i.type == e.CHILD && (null !== (t = (o = i)._$AP) && void 0 !== t || (o._$AP = h), null !== (s = (r = i)._$AQ) && void 0 !== s || (r._$AQ = n));
};
class c extends t {
  constructor() {
    super(...arguments), this._$AN = void 0;
  }
  _$AT(i, t, e) {
    super._$AT(i, t, e), r(this), this.isConnected = i._$AU;
  }
  _$AO(i, t = !0) {
    var e, r;
    i !== this.isConnected && (this.isConnected = i, i ? null === (e = this.reconnected) || void 0 === e || e.call(this) : null === (r = this.disconnected) || void 0 === r || r.call(this)), t && (s(this, i), o(this));
  }
  setValue(t) {
    if (i(this._$Ct)) this._$Ct._$AI(t, this);else {
      const i = [...this._$Ct._$AH];
      i[this._$Ci] = t, this._$Ct._$AI(i, this, 0);
    }
  }
  disconnected() {}
  reconnected() {}
}
export { c as AsyncDirective };
