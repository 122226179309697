import { noChange as t } from "../lit-html.js";
import { directive as i, Directive as s, PartType as r } from "../directive.js";
/**
 * @license
 * Copyright 2018 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const o = i(class extends s {
  constructor(t) {
    var i;
    if (super(t), t.type !== r.ATTRIBUTE || "class" !== t.name || (null === (i = t.strings) || void 0 === i ? void 0 : i.length) > 2) throw Error("`classMap()` can only be used in the `class` attribute and must be the only part in the attribute.");
  }
  render(t) {
    return " " + Object.keys(t).filter(i => t[i]).join(" ") + " ";
  }
  update(i, [s]) {
    var r, o;
    if (void 0 === this.it) {
      this.it = new Set(), void 0 !== i.strings && (this.nt = new Set(i.strings.join(" ").split(/\s/).filter(t => "" !== t)));
      for (const t in s) s[t] && !(null === (r = this.nt) || void 0 === r ? void 0 : r.has(t)) && this.it.add(t);
      return this.render(s);
    }
    const e = i.element.classList;
    this.it.forEach(t => {
      t in s || (e.remove(t), this.it.delete(t));
    });
    for (const t in s) {
      const i = !!s[t];
      i === this.it.has(t) || (null === (o = this.nt) || void 0 === o ? void 0 : o.has(t)) || (i ? (e.add(t), this.it.add(t)) : (e.remove(t), this.it.delete(t)));
    }
    return t;
  }
});
export { o as classMap };
