import { InjectionToken } from '@angular/core';
const ENVIRONMENT = new InjectionToken('ENVIRONMENT');
const ENVIRONMENT$ = new InjectionToken('ENVIRONMENT$');
const APP_NAME = new InjectionToken('APP_NAME');
const LOCALE = new InjectionToken('LOCALE');
const THEME_SERVICE = new InjectionToken('THEME_SERVICE');
const LOGGER_SERVICE = new InjectionToken('LOGGER_SERVICE');
const AUTHORIZED_ROUTES_SERVICE = new InjectionToken('AUTHORIZED_ROUTES_SERVICE');
const SETTINGS_SERVICE = new InjectionToken('SETTINGS_SERVICE');
const SYSTEM_SERVICE = new InjectionToken('SYSTEM_SERVICE');
const BASE_API_SERVICE = new InjectionToken('BASE_API_SERVICE');
const TASK_API_SERVICE = new InjectionToken('TASK_API_SERVICE');
const TOAST_SERVICE = new InjectionToken('TOAST_SERVICE');
const HEADERBAR_PORTAL_SERVICE = new InjectionToken('HEADERBAR_PORTAL_SERVICE');
const TRANSLATION_SETTINGS = new InjectionToken('TRANSLATION_SETTINGS');

/**
 * Generated bundle index. Do not edit.
 */

export { APP_NAME, AUTHORIZED_ROUTES_SERVICE, BASE_API_SERVICE, ENVIRONMENT, ENVIRONMENT$, HEADERBAR_PORTAL_SERVICE, LOCALE, LOGGER_SERVICE, SETTINGS_SERVICE, SYSTEM_SERVICE, TASK_API_SERVICE, THEME_SERVICE, TOAST_SERVICE, TRANSLATION_SETTINGS };
